/**
 * 品牌馆-新闻资讯
 * luxinwen
 * 2023-04-17
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_BRAND_NEWS_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.title" placeholder="请输入标题" clearable />
          </FormItem>
          <FormItem>
            <DatePicker class="width-m" v-model="formData.time" type="datetimerange" placeholder="请选择时间" />
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content" v-grant="'OSP_BRAND_NEWS_SAVE'">
        <Button type="primary" @click="addData">新增新闻资讯</Button>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row, true)" v-grant="'OSP_BRAND_NEWS_VIEW'">查看</Button>
            <Button @click="editData(params.row, false)" v-grant="'OSP_BRAND_NEWS_UPDATE'">编辑</Button>
            <Button type="error" @click="removeData(params.row)" v-grant="'OSP_BRAND_NEWS_DELETE'">删除</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
  </div>
</template>

<script>
  import editDrawer from './edit.vue';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        formData: {
          title: '', // 标题
          time: [] // 时间
        },
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70
          },
          {
            title: '标题',
            key: 'title'
          },
          {
            title: '发布时间',
            key: 'publishTime'
          },
          {
            title: '发布人',
            key: 'publisher'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_BRAND_NEWS_VIEW, this.$grant.OSP_BRAND_NEWS_UPDATE, this.$grant.OSP_BRAND_NEWS_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          tags: 'news',
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        if (this.formData.time.length > 1) {
          data.startTime = this.formatDate(this.formData.time[0]);
          data.endTime = this.formatDate(this.formData.time[1]);
        }
        delete data.time;
        this.$axios({
          url: this.$api.news.queryNewsList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 新增数据
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {};
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑
       */
      editData(row, onlyRoad = false) {
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 删除数据
       */
      removeData(row) {
        this.$confirm(`确定要删除 [${row.title}] 吗？`).then(() => {
          this.$axios({
            url: this.$api.news.deleteNews,
            data: {
              ids: [row.id]
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>